import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';

// var mySplitText = new SplitText('h1', {type:'words, chars'})

export default {
  init() {
    // JavaScript to be fired on the home page

    let tl = gsap.timeline({defaults: {ease: 'power4.inOut', duration: 2 }})

    let greenbar = CSSRulePlugin.getRule('.subheader-content-wrapper:before');
    let blackbar = CSSRulePlugin.getRule('.subheader-content-wrapper:after');

    tl.to('.page-header-home', { backgroundSize: 125, opacity: 1, duration: 2.5, ease: 'Expo.EaseOut'})
      .to('h1', { 'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)', opacity: 1, y: 0, duration: 2}, '-=3')
      .from('#toon_tickets', {y:100, opacity:0, ease:'back(4)', duration:0.8}, '<1')
      .to('.subheader-content-wrapper', {opacity: 1, duration: 0.2 }, '-=3')
      .to('.subheader-content-wrapper p', { 'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)', opacity: 1, y: 0, duration: 1.5}, '-=3')
      .from(greenbar, { ease:'back(1)', transform: 'translateX(-450px)' }, '-=2.8')
      .from(blackbar, { ease:'back(1)', transform: 'translateX(-450px)' }, '-=3')
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};


