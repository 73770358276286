import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


export default {
  init() {

    //gsap animaties:
      
    gsap.utils.toArray('.is-style-indrukwekkend-unfold').forEach(function(elem) {
      // hide(elem); // assure that the element is hidden when scrolled into view, ?

      let figure= elem.querySelector('figure');
      let content = elem.querySelector('.wp-block-media-text__content');
      hide(figure); // assure that the element is hidden when scrolled into view
      hide(content); // assure that the element is hidden when scrolled into view
      
      ScrollTrigger.create({
        trigger: elem,
        start: 'top bottom-=100',
        // end: '-=200',
        // markers: true,
        onEnter: function() { animateFrom(elem) }, 
        onEnterBack: function() { animateFrom(elem, -1) },
        onLeave: function() { hide(figure), hide(content) }, // assure that the element is hidden when scrolled into view
        onLeaveBack: function() { hide(figure), hide(content) }, // assure that the element is hidden when scrolled into view

      });
    });

    gsap.utils.toArray('.wp-block-indrukwekkend-single-project-large').forEach(function(elem) {

      let figure= elem.querySelector('picture');
      let title = elem.querySelector('.card-content h2');
      let text = elem.querySelector('.card-content__post-excerpt');
      let footer = elem.querySelector('.card-content .footer');

      // set values immediately (no animation)
      // gsap.set( [ figure ], {autoAlpha: 0, size: 60 });

      let tl = gsap.timeline({defaults: {ease: 'power4.inOut', duration: 1.5 }})

      tl.from(figure, { scale:0.8, autoAlpha: 0, duration: 1.5, size: 100, ease: 'Expo.EaseOut'})
        .from(title, {autoAlpha: 0, y: 30}, '<')
        .from(text, {autoAlpha: 0, y: 30}, '<+=0.2')
        .from(footer, {autoAlpha: 0, y: 30}, '<+=0.3')

      tl.pause();

      ScrollTrigger.create({
        trigger: elem,
        start: 'top bottom-=50%',
        // end: '-=200',
        // markers: true,
        onEnter: function() { tl.resume(); }, 
        // onEnterBack: function() { animateLargeFrom(elem, -1) },
        // onLeave: function() { hide(figure), hide(content) }, // assure that the element is hidden when scrolled into view
        // onLeaveBack: function() { hide(figure), hide(content) }, // assure that the element is hidden when scrolled into view
      });
    })
  },
};


function animateFrom(elem, direction) {
  direction = direction | 1;
  
  var x = 0,
      y = direction * 100;

  if(elem.classList.contains('has-media-on-the-right')) {
    x = 100;
    y = 0;
  } else {
    x = -100;
    y = 0;
  }

  let figure= elem.querySelector('figure');

  gsap.fromTo(figure, {x: x, y: y, autoAlpha: 0}, {
    duration: 2.25, 
    x: 0,
    y: 0, 
    autoAlpha: 1, 
    ease: 'expo', 
    overwrite: 'auto',
  });

  let content = elem.querySelector('.wp-block-media-text__content');

  x = 0,
  y = direction * 100;

  gsap.fromTo(content, {x: x, y: y, autoAlpha: 0}, {
    duration: 2.25, 
    x: 0,
    y: 0, 
    autoAlpha: 1, 
    ease: 'expo', 
    overwrite: 'auto',
  });
}

function hide(elem) {
  gsap.set(elem, {autoAlpha: 0});
}


