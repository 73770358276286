import { gsap } from 'gsap';

const config = { threshold: 0.5 };

export default {
  init() {
    // JavaScript to be fired on the shop page

    // Intersection observer code
    let observer = new IntersectionObserver(function(entries, self) { 
      console.log(entries);
      let targets = entries.map(entry => {
        if(entry.isIntersecting) {
          self.unobserve(entry.target);
          return entry.target;
        }
      });

      // Call our animation function
      fadeIn(targets);
    }, config);
 
    document.querySelectorAll('.product').forEach(box => {
      observer.observe(box);
    });
  },
};

  // Fades in the targets given 
  function fadeIn(targets) { 
    
    // Using GSAP's staggers!
    gsap.to(targets, { 
      opacity: 1, 
      stagger: 0.1,
      ease: 'expo',
    });
  }